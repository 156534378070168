import api from '@/api';
export const addClientAccessToContractor = async (contractorId, clientIds) => {
    await api.post(`/access/contractors/${contractorId}/clients`, { clientIds });
};
export const deleteClientAccessFromContractor = async (contractorId, clientIds) => {
    await api.delete(`/access/contractors/${contractorId}/clients`, {
        params: { clientIds },
    });
};
export const addBrandAccessToContractor = async (contractorId, brandIds) => {
    await api.post(`/access/contractors/${contractorId}/brands`, { brandIds });
};
export const deleteBrandAccessFromContractor = async (contractorId, brandIds) => {
    await api.delete(`/access/contractors/${contractorId}/brands`, {
        params: { brandIds },
    });
};
export const addStructureAccessToContractor = async (contractorId, structureIds) => {
    await api.post(`/access/contractors/${contractorId}/structures`, {
        structureIds,
    });
};
export const deleteStructureAccessFromContractor = async (contractorId, structureIds) => {
    await api.delete(`/access/contractors/${contractorId}/structures`, {
        params: { structureIds },
    });
};
export const addPlaceAccessToContractor = async (contractorId, placeIds) => {
    await api.post(`/access/contractors/${contractorId}/places`, { placeIds });
};
export const deletePlaceAccessFromContractor = async (contractorId, placeIds) => {
    await api.delete(`/access/contractors/${contractorId}/places`, {
        params: { placeIds },
    });
};
export const addObjectAccessToContractor = async (contractorId, objectIds) => {
    await api.post(`/access/contractors/${contractorId}/objects`, { objectIds });
};
export const deleteObjectAccessFromContractor = async (contractorId, objectIds) => {
    await api.delete(`/access/contractors/${contractorId}/objects`, {
        params: { objectIds },
    });
};
