import { defineComponent, ref } from '@vue/composition-api';
import { Btn, BtnTheme, BtnSize } from '@/components/Btn';
import ModalDisplay from '@/components/Modal/ModalDisplay.vue';
import AccessTree from '@/views/Dashboard/Contractors/_components/AccessTree/AccessTree.vue';
import ObjectsAccess from '@/views/Dashboard/Contractors/_components/ObjectsAccess/ObjectsAccess.vue';
export default defineComponent({
    components: { ObjectsAccess, AccessTree, ModalDisplay, Btn },
    props: {
        contractorId: {
            type: String,
            required: true,
        },
    },
    setup() {
        const showModal = ref(false);
        const updateTreeCount = ref(0);
        const scrollContainer = 'objects-scroll-container';
        return {
            showModal,
            BtnTheme,
            BtnSize,
            updateTreeCount,
            scrollContainer,
        };
    },
});
