import { AccessType, ADD_ACCESS_FN_MAP, DELETE_ACCESS_FN_MAP, } from '@/views/Dashboard/Contractors/_components/AccessTree';
import { ref } from '@vue/composition-api';
export const useAccessManagement = (contractorId) => {
    const loading = ref(false);
    const changeAccess = (fnMap, node) => fnMap[node.nodeType](contractorId, [node.id]);
    const toggleAccess = async (node) => {
        const fnMap = node.accessType === AccessType.DIRECT
            ? DELETE_ACCESS_FN_MAP
            : ADD_ACCESS_FN_MAP;
        await changeAccess(fnMap, node);
    };
    return { toggleAccess, loading };
};
