import { render, staticRenderFns } from "./ContractorDetails.vue?vue&type=template&id=f04c9708&scoped=true&"
import script from "./ContractorDetails.vue?vue&type=script&lang=js&"
export * from "./ContractorDetails.vue?vue&type=script&lang=js&"
import style0 from "./ContractorDetails.vue?vue&type=style&index=0&id=f04c9708&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f04c9708",
  null
  
)

export default component.exports