import { addBrandAccessToContractor, addClientAccessToContractor, addPlaceAccessToContractor, addStructureAccessToContractor, deleteBrandAccessFromContractor, deleteClientAccessFromContractor, deletePlaceAccessFromContractor, deleteStructureAccessFromContractor, } from '@/api/requests/access';
export var NodeType;
(function (NodeType) {
    NodeType["CLIENT"] = "client";
    NodeType["BRAND"] = "brand";
    NodeType["STRUCTURE"] = "structure";
    NodeType["PLACE"] = "place";
})(NodeType || (NodeType = {}));
export var AccessType;
(function (AccessType) {
    AccessType["NONE"] = "NONE";
    AccessType["DIRECT"] = "DIRECT";
    AccessType["INDIRECT"] = "INDIRECT";
})(AccessType || (AccessType = {}));
export const ADD_ACCESS_FN_MAP = {
    [NodeType.CLIENT]: addClientAccessToContractor,
    [NodeType.BRAND]: addBrandAccessToContractor,
    [NodeType.STRUCTURE]: addStructureAccessToContractor,
    [NodeType.PLACE]: addPlaceAccessToContractor,
};
export const DELETE_ACCESS_FN_MAP = {
    [NodeType.CLIENT]: deleteClientAccessFromContractor,
    [NodeType.BRAND]: deleteBrandAccessFromContractor,
    [NodeType.STRUCTURE]: deleteStructureAccessFromContractor,
    [NodeType.PLACE]: deletePlaceAccessFromContractor,
};
export const TREE_UPDATE_DELAY = 1000;
