import { computed, defineComponent, ref, watch, } from '@vue/composition-api';
import Tree from '@/components/Tree/Tree.vue';
import { usePromise } from '@/composables';
import { getOrganizationsList, OrganizationType, } from '@/api/requests/organizations';
import { AccessTreeItem, AccessType, NodeType, TREE_UPDATE_DELAY, useAccessManagement, useAccessTreeContent, } from '@/views/Dashboard/Contractors/_components/AccessTree';
import { Checkbox } from '@/components/Inputs';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
import { loadAllResults } from '@/api/requests/pagination';
export default defineComponent({
    components: { LoadingSpinner, Checkbox, AccessTreeItem, Tree },
    props: {
        contractorId: {
            type: String,
            required: true,
        },
        updateCount: Number,
    },
    setup(props) {
        const { result: clients, loading, error, } = usePromise(loadAllResults(getOrganizationsList, {
            type: OrganizationType.CLIENT,
            contractorIdAccess: props.contractorId,
        }));
        const { getTreeNode, loadChildren, loadMore } = useAccessTreeContent(props.contractorId);
        const clientNodes = computed(() => clients.value?.map((client) => getTreeNode(client, NodeType.CLIENT)) ||
            []);
        const { toggleAccess, loading: updatingAccess } = useAccessManagement(props.contractorId);
        const isStructureExpandable = (structureNode) => structureNode.hasChildren || structureNode.hasAnyPlace;
        const isExpandable = (node) => {
            switch (node.nodeType) {
                case NodeType.PLACE:
                    return false;
                case NodeType.STRUCTURE:
                    return isStructureExpandable(node);
                default:
                    return true;
            }
        };
        const recalculatedNode = ref(null);
        const disabled = computed(() => {
            return loading.value || updatingAccess.value || !!recalculatedNode.value;
        });
        const isNodeRecalculating = (nodeId) => {
            return recalculatedNode.value?.id === nodeId;
        };
        const onToggleAccess = async (node, callback) => {
            try {
                recalculatedNode.value = node;
                await toggleAccess(node);
                await callback();
            }
            finally {
                setTimeout(() => {
                    recalculatedNode.value = null;
                }, TREE_UPDATE_DELAY);
            }
        };
        const replaceAccessType = (nodes, updatedData) => {
            updatedData.forEach((updatedNode) => {
                const node = nodes.value.find(({ id }) => id === updatedNode.id);
                if (node) {
                    node.accessType = updatedNode.accessType;
                }
            });
        };
        const onReloadData = async () => {
            const updatedClients = await loadAllResults(getOrganizationsList, {
                type: OrganizationType.CLIENT,
                contractorIdAccess: props.contractorId,
            });
            replaceAccessType(clientNodes, updatedClients);
        };
        watch(() => props.updateCount, async () => {
            updatingAccess.value = true;
            await onReloadData();
            setTimeout(() => {
                updatingAccess.value = false;
            }, TREE_UPDATE_DELAY);
        });
        return {
            AccessType,
            loading,
            error,
            clientNodes,
            loadChildren,
            isExpandable,
            loadMore,
            toggleAccess,
            disabled,
            onToggleAccess,
            onReloadData,
            replaceAccessType,
            isNodeRecalculating,
        };
    },
});
