import { computed, defineComponent } from '@vue/composition-api';
import { AccessType, NodeType, } from '@/views/Dashboard/Contractors/_components/AccessTree';
import TreeItemBadge from '@/views/Dashboard/Contractors/_components/AccessTree/_components/TreeItemBadge.vue';
export default defineComponent({
    components: { TreeItemBadge },
    props: {
        node: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const label = computed(() => props.node.name);
        const showBadge = computed(() => [NodeType.BRAND, NodeType.CLIENT].includes(props.node.nodeType));
        const hasIndirectAccess = computed(() => {
            return props.node.accessType === AccessType.INDIRECT;
        });
        const isPlace = computed(() => props.node.nodeType === NodeType.PLACE);
        return {
            showBadge,
            label,
            hasIndirectAccess,
            isPlace,
        };
    },
});
