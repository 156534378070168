<template>
  <split-layout :left-width="57" :right-width="43">
    <template v-if="contractor" #leftSide>
      <div class="md:pb-16 pt-5">
        <div class="sm:pr-10 h-full">
          <router-link
            :to="{ name: 'Contractors' }"
            class="text-gray-dark text-xs inline-flex items-center mb-2"
          >
            <icon :icon="mdiChevronLeft" class="text-gray-dark" />
            {{ $t('common.back') }}
          </router-link>
          <div class="mb-12 flex flex-col md:flex-row md:items-stretch">
            <div class="logo self-center">
              <image-view
                :src="contractor.cdnLogoUrl"
                :size="ImageSize.CARD"
                alt="logo"
              />
            </div>
            <div>
              <h1 class="text-3xl leading-tight">{{ contractor.name }}</h1>
              <div class="text-sm font-semibold flex mt-3">
                <icon
                  :icon="mdiHomeOutline"
                  :size="IconSize.SM"
                  class="mt-px mr-2 text-gray-dark flex-shrink-0"
                />
                <div>
                  <p>
                    {{ contractor.address }}, {{ contractor.postalCode }}
                    {{ contractor.city }}
                  </p>
                  <p class="mt-3">
                    <span class="info-line">
                      {{ $t('contractor-details-view.nip') }}:
                    </span>
                    {{ contractor.nip }}
                  </p>
                  <p>
                    <span class="info-line">
                      {{ $t('contractor-details-view.regon') }}:
                    </span>
                    {{ contractor.regon }}
                  </p>
                  <p>
                    <span class="info-line">
                      {{ $t('contractor-details-view.krs') }}:
                    </span>
                    {{ contractor.krs }}
                  </p>
                </div>
              </div>
              <div class="flex mt-8">
                <router-link
                  :to="{
                    name: 'EditContractor',
                    params: { contractorId: contractor.id },
                  }"
                >
                  <icon
                    :icon="EditSvg"
                    class="mr-4 text-primary"
                    :disabled="deletionInProgress"
                  />
                </router-link>

                <modal-wrapper
                  v-slot="{ runWithModal }"
                  :content="deleteModalContent"
                  class="cursor-pointer"
                >
                  <icon
                    :icon="DeleteSvg"
                    class="mr-4 text-primary"
                    :disabled="deletionInProgress"
                    @click.native="
                      runWithModal(onContractorDelete, deleteModalContent)
                    "
                  />
                </modal-wrapper>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <h2 class="font-semibold text-lg">
                {{ $t('contractor-details-view.users') }}
              </h2>
              <pagination-select
                :query="routeQuery"
                @select-limit="selectLimit"
              />
            </div>
            <div class="flex">
              <access-modal :contractor-id="contractor.id" />
              <btn
                :theme="BtnTheme.SECONDARY"
                :size="BtnSize.LG"
                class="min-w-32 md:min-w-48 ml-4"
                :is-loading="deletionInProgress"
                @click="$router.push({ name: 'AddContractorUser' })"
              >
                {{ $t('contractor-details-view.add-user') }}
              </btn>
            </div>
          </div>
          <tabs class="mt-4.5 md:mb-16">
            <tab :name="$t('contractor-details-view.all')" selected>
              <table-list
                content="users"
                :query="{ organizationId: $route.params.contractorId }"
                route-component="ContractorDetails"
                class="md:pb-16 hover-click"
                :loading="deletionInProgress"
                :cols="columns"
                :force-update="listUpdatesCounter"
                ellipsis-only
                :mobile-cols="1"
                :actions="{
                  edit: onUserEdit,
                  delete: {
                    handler: onUserDelete,
                    modal: {
                      type: 'confirm',
                      title: $t('contractor-details-view.modal-title'),
                      description: $t(
                        'contractor-details-view.modal-description',
                      ),
                      btnText: $t('contractor-details-view.modal-btn'),
                    },
                  },
                }"
                checkbox
                :selected-ids="selectedUsers"
                @record-click="
                  $router.push({
                    name: 'ContractorUserDetails',
                    params: {
                      contractorId: $route.params.contractorId,
                      userId: $event,
                    },
                  })
                "
                @select="selectedUsers = $event"
              />
            </tab>
          </tabs>
        </div>
      </div>
    </template>
    <template #rightSide>
      <tabs class="p-8">
        <tab :name="$t('contractor-details-view.documents')" selected>
          <upload-list
            v-if="showAttachments('multimedia')"
            type="multimedia"
            :list="attachments.multimedia"
            read-only
            class="mt-6"
          />
          <p v-else class="file-list-placeholder">
            {{ $t('contractor-form.multimedia-placeholder') }}
          </p>
          <upload-list
            v-if="showAttachments('document')"
            type="document"
            :list="attachments.document"
            read-only
            class="mt-6"
          />
          <p v-else class="file-list-placeholder">
            {{ $t('contractor-form.documents-placeholder') }}
          </p>
        </tab>
        <tab
          :name="`${$t('contractor-details-view.structures')} / ${$t(
            'contractor-details-view.places',
          )}`"
        >
          <structures-places-list read-only />
        </tab>
      </tabs>
    </template>
    <overlap-sidebar :is-open="sidebarOpen" @close="sidebarOpen = false">
      <clients-sidebar class="mt-4" />
    </overlap-sidebar>
  </split-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Btn, BtnTheme, BtnSize } from '@/components/Btn';
import { Icon, IconSize } from '@/components/Icon';
import Tabs from '@/components/Tabs/Tabs.vue';
import Tab from '@/components/Tabs/Tab.vue';
import UploadList from '@/components/Uploader/UploadList.vue';
import SplitLayout from '@/layouts/SplitLayout.vue';
import OverlapSidebar from '@/components/Sidebar/OverlapSidebar.vue';
import ClientsSidebar from '@/views/Dashboard/Clients/ClientsSidebar.vue';
import VueStore from '@/store';
import TableList from '@/components/Table/TableList';
import ModalWrapper from '@/components/Modal/ModalWrapper';
import { email } from '@/util/formatters';
import { SUBCONTRACTOR } from '@/store/modules/contractor';
import { PaginationSelect } from '@/components/Inputs';
import StructuresPlacesList from '@/views/Dashboard/Contractors/_components/StructuresPlacesList';
import { ImageView, ImageSize } from '@/components/ImageView';
import routeQuery from '@/mixins/query/routeQuery';
import { mdiChevronLeft, mdiHomeOutline } from '@mdi/js';
import EditSvg from '@/assets/icons/edit.svg';
import DeleteSvg from '@/assets/icons/delete.svg';
import AccessModal from '@/views/Dashboard/Contractors/_components/AccessModal/AccessModal';

export default {
  name: 'ContractorDetails',
  components: {
    AccessModal,
    StructuresPlacesList,
    TableList,
    Btn,
    Icon,
    Tabs,
    Tab,
    SplitLayout,
    UploadList,
    OverlapSidebar,
    ClientsSidebar,
    ModalWrapper,
    PaginationSelect,
    ImageView,
  },
  data() {
    return {
      IconSize,
      mdiChevronLeft,
      mdiHomeOutline,
      EditSvg,
      DeleteSvg,
      sidebarOpen: false,
      mocked: {
        logoUrl: '/uploads/mock-1.png',
      },
      listUpdatesCounter: 0,
      deletionInProgress: false,
      selectedUsers: [],
      ImageSize,
      BtnTheme,
      BtnSize,
    };
  },
  mixins: [routeQuery],
  computed: {
    ...mapState('contractor', {
      contractor: 'details',
      brands: 'brands',
    }),
    ...mapState('attachment', ['attachments']),
    columns() {
      return {
        firstName: {
          label: this.$t('contractor-details-view.name'),
          merge: {
            props: ['firstName', 'lastName'],
            format: '$0 $1',
          },
          colClass: ['w-46', 'lg:w-auto'],
        },
        email: {
          label: this.$t('contractor-details-view.e-mail'),
          formatter: email,
        },
        role: {
          label: this.$t('contractor-details-view.subcontractor'),
          formatter: this.formatLabel,
          colClass: ['w-24', 'lg:w-auto'],
        },
      };
    },
    deleteModalContent() {
      return {
        type: 'confirm',
        title: this.$t('contractors-view.modal-title'),
        description: this.$t('contractors-view.modal-description'),
        btnText: this.$t('contractors-view.modal-btn'),
      };
    },
  },
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      VueStore.dispatch('attachment/clearAttachmentList'),
      VueStore.dispatch(
        'contractor/fetchContractorDetails',
        to.params['contractorId'],
      ),
      VueStore.dispatch('attachment/fetchAttachments', {
        id: to.params.contractorId,
        target: 'organizations',
        type: 'document',
      }),
      VueStore.dispatch('attachment/fetchAttachments', {
        id: to.params.contractorId,
        target: 'organizations',
        type: 'multimedia',
      }),
    ]);

    next();
  },
  async beforeRouteLeave(to, from, next) {
    await VueStore.dispatch('contractor/clearState');
    next();
  },
  methods: {
    ...mapActions('common', ['delete']),
    onUserEdit(id) {
      this.$router.push({
        name: 'EditContractorUser',
        params: { userId: id, contractorId: this.$route.params.contractorId },
      });
    },
    async onUserDelete(id) {
      await this.delete({ type: 'users', id });

      this.listUpdatesCounter++;
      this.$toast.success(
        this.$t('contractor-details-view.deleted-successfully'),
      );
    },
    formatLabel(value) {
      const isSubcontractor = value === SUBCONTRACTOR;
      const statusClass = isSubcontractor ? 'success' : 'icons';
      return `<span class="px-3 py-0.5 w-16 text-center font-normal border text-3xs capitalize rounded-full border-${statusClass} text-${statusClass}">${this.$t(
        `contractor-details-view.status-${isSubcontractor}`,
      )}</span>`;
    },
    showAttachments(type) {
      return (
        this.attachments &&
        this.attachments[type] &&
        this.attachments[type].length
      );
    },
    async onContractorDelete() {
      this.deletionInProgress = true;
      try {
        await this.delete({ type: 'organizations', id: this.contractor.id });

        this.$router.push({ name: 'Contractors' });

        this.$toast.success(this.$t('contractors-view.deleted-successfully'));
      } finally {
        this.deletionInProgress = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.logo {
  @apply h-70 w-full max-w-88 bg-white border border-gray-light rounded flex items-center justify-center overflow-hidden mb-10;

  @screen sm {
    @apply mr-8 w-62 h-52;
  }
}

.info-line {
  @apply text-gray-darker font-normal inline-block w-16 mr-3;
}

.file-list-placeholder {
  @apply border rounded border-gray-dark mt-8 text-gray-dark text-3xl py-4 text-center;
}
</style>
