<template>
  <div>
    <p>
      {{ label }}
      <span v-if="required" class="text-red">*</span>
      <span v-if="errorsMsg" class="text-red text-xs ml-1">
        {{ errorsMsg }}
      </span>
    </p>
    <slot v-if="customList"></slot>
    <table-grid
      v-else-if="value.length"
      :with-thumbs="withThumbs"
      thumb-key="cdnImageUrl"
      :thumb-cdn="IMAGE_THUMB_SIZE"
      :rows="value"
      :cols="cols"
      :remove-btns="removeBtns && !disabled"
      :status-options="statusOptions"
      :status-config="statusConfig"
      :status-colors="statusColors"
      class="hover-click"
      @remove="$emit('remove', $event)"
      @record-click="$emit('record-click', $event)"
    />
    <div v-else class="border border-gray-dark rounded my-2">
      <div class="list-placeholder" :class="{ disabled }">
        <button
          :disabled="disabled"
          type="button"
          @click="$emit('open-sidebar')"
        >
          <span>{{ placeholder }}</span>
          <icon :icon="mdiPlus" class="plus-icon"></icon>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import TableGrid from '@/components/Table/TableGrid/TableGrid';
import { Icon } from '@/components/Icon';
import { IMAGE_THUMB_SIZE } from '@/util/images';
import { mdiPlus } from '@mdi/js';

export default {
  components: { TableGrid, Icon },
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: [Boolean, String],
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    cols: {
      type: Object,
      default: () => ({}),
    },
    withThumbs: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    removeBtns: {
      type: Boolean,
      default: false,
    },
    customList: {
      type: Boolean,
      default: false,
    },
    statusOptions: {
      type: Function,
      default: () => [],
    },
    statusColors: {
      type: Object,
      default: () => ({}),
    },
    statusConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      mdiPlus,
      IMAGE_THUMB_SIZE,
    };
  },
  computed: {
    errorsMsg() {
      return typeof this.isError === 'string' ? this.isError : '';
    },
  },
  watch: {
    value(value) {
      this.$emit('input', value);
    },
  },
};
</script>
<style scoped lang="scss">
.list-placeholder {
  @apply text-lg text-primary py-2;

  button {
    @apply flex items-center justify-center w-full;

    .plus-icon {
      @apply text-primary ml-2;
    }
  }

  &.disabled {
    @apply text-gray-dark;

    button {
      @apply cursor-not-allowed;
      .plus-icon {
        @apply fill-gray-dark;
      }
    }
  }
}
</style>
