var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.brands),function(brand){return _c('div',{key:brand.id,staticClass:"brand-wrapper"},[_c('label',{staticClass:"brand-label"},[_vm._v(" "+_vm._s(_vm.$t('contractor-form.brand'))+": "),_c('span',{staticClass:"text-primary font-bold ml-1"},[_vm._v(_vm._s(brand.name))])]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('list',{attrs:{"value":_vm.structures[brand.id],"label":_vm.$t('contractor-form.structures'),"required":"","is-error":errors[0],"remove-btns":!_vm.readOnly,"cols":_vm.structureFields,"placeholder":_vm.$t('contractor-form.no-structures')},on:{"open-sidebar":function($event){return _vm.$emit('open-sidebar', { brand: brand, tab: 'structures' })},"remove":function($event){return _vm.removeStructure({ brandId: brand.id, id: $event.id })}}})]}}],null,true)}),(!_vm.show)?_c('div',[_c('div',{staticClass:"flex"},[_c('p',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t('contractor-form.places'))+":")]),(_vm.edit)?_c('fetch-data',{staticClass:"flex",attrs:{"url":"places","params":{
            contractorId: _vm.$route.params.contractorId,
            limit: 1,
            brandId: brand.id,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var pagination = ref.pagination;
return [_c('p',{staticClass:"font-semibold"},[_vm._v(" "+_vm._s(pagination ? _vm.getSelectedPlacesCount(pagination.total, brand.id) : 0)+" ")])]}}],null,true)}):_c('p',{staticClass:"font-semibold"},[_vm._v(" "+_vm._s(_vm.getSelectedPlacesCount(0, brand.id))+" ")])],1),_c('btn',{staticClass:"focus:outline-none w-50 secondary md mt-4",on:{"click":function($event){return _vm.$emit('open-sidebar', { brand: brand, tab: 'places' })}}},[_vm._v(" "+_vm._s(_vm.$t('contractor-form.show-all'))+" ")])],1):_vm._e(),(_vm.show)?_c('div',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('list',{attrs:{"value":_vm.places[brand.id],"label":_vm.$t('contractor-form.places'),"required":"","custom-list":true,"is-error":errors[0],"cols":_vm.getPlaceCols(brand),"placeholder":_vm.$t('contractor-form.no-places')},on:{"remove":function($event){return _vm.removePlace({ brandId: brand.id, id: $event.id })},"open-sidebar":function($event){return _vm.$emit('open-sidebar', { brand: brand, tab: 'places' })}}},[(brand)?_c('TableList',{ref:"placeTable",refInFor:true,attrs:{"cols":_vm.getPlaceCols(brand),"content":"places","query":{
              contractorId: _vm.$route.params.contractorId,
              brandId: brand.id,
            },"remove-btns":!_vm.readOnly,"hide-labels":""},on:{"remove":function($event){return _vm.removePlace({ brandId: brand.id, id: $event.id })},"select":function (ids, items) { return _vm.$emit('select', ids, items); }}}):_vm._e()],1)]}}],null,true)})],1):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }