import { DEFAULT_LIMIT } from '@/constants/pagination';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { ExpandButtonStyle } from '@/components/Table/TableGrid/table.constants';
import { GenericTable } from '@/components/Table/__new__/_components/TableGeneric.vue';
import { TableStyle } from '@/components/Table/__new__';
import InfiniteData from '@/components/DataProvider/InfiniteData.vue';
import { AccessType } from '@/views/Dashboard/Contractors/_components/AccessTree';
import { addObjectAccessToContractor, deleteObjectAccessFromContractor, } from '@/api/requests/access';
import { SelectInput, Checkbox } from '@/components/Inputs';
import debounce from 'lodash.debounce';
import { Debounce } from '@/constants/debounce';
export default defineComponent({
    components: {
        SelectInput,
        Checkbox,
        TableDisplay: GenericTable(),
        InfiniteData,
    },
    props: {
        contractorId: {
            type: String,
            required: true,
        },
        scrollContainerSelector: {
            type: String,
            required: true,
        },
    },
    setup(props, ctx) {
        const updatedObjectId = ref(null);
        const debouncedEmit = debounce(ctx.emit, Debounce.LAZY);
        const onSelectObject = async (object) => {
            updatedObjectId.value = object.id;
            try {
                if (object.accessType === AccessType.NONE) {
                    await addObjectAccessToContractor(props.contractorId, [object.id]);
                    object.accessType = AccessType.DIRECT;
                }
                else {
                    await deleteObjectAccessFromContractor(props.contractorId, [
                        object.id,
                    ]);
                    object.accessType = AccessType.NONE;
                }
                debouncedEmit('update-access');
            }
            finally {
                updatedObjectId.value = null;
            }
        };
        const columns = computed(() => {
            return [
                {
                    key: 'checkbox',
                },
                {
                    key: 'objectType',
                    formatter: (objectType) => objectType.name,
                    cellClass: 'font-bold pl-1 max-w-50',
                },
                {
                    key: 'internalNumber',
                    cellClass: 'font-bold',
                },
                {
                    key: 'place',
                    formatter: (place) => place.number,
                    cellClass: 'font-bold',
                },
                {
                    key: 'address',
                    gridColumn: '1fr',
                },
            ];
        });
        const objectType = ref(null);
        const objectQuery = computed(() => {
            return {
                limit: DEFAULT_LIMIT,
                contractorIdAccess: props.contractorId,
                ...(objectType.value && { objectTypeId: objectType.value.id }),
            };
        });
        return {
            objectType,
            AccessType,
            objectQuery,
            columns,
            ExpandButtonStyle,
            onSelectObject,
            TableStyle,
            updatedObjectId,
        };
    },
});
