var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('split-layout',{attrs:{"left-width":57,"right-width":43},scopedSlots:_vm._u([(_vm.contractor)?{key:"leftSide",fn:function(){return [_c('div',{staticClass:"md:pb-16 pt-5"},[_c('div',{staticClass:"sm:pr-10 h-full"},[_c('router-link',{staticClass:"text-gray-dark text-xs inline-flex items-center mb-2",attrs:{"to":{ name: 'Contractors' }}},[_c('icon',{staticClass:"text-gray-dark",attrs:{"icon":_vm.mdiChevronLeft}}),_vm._v(" "+_vm._s(_vm.$t('common.back'))+" ")],1),_c('div',{staticClass:"mb-12 flex flex-col md:flex-row md:items-stretch"},[_c('div',{staticClass:"logo self-center"},[_c('image-view',{attrs:{"src":_vm.contractor.cdnLogoUrl,"size":_vm.ImageSize.CARD,"alt":"logo"}})],1),_c('div',[_c('h1',{staticClass:"text-3xl leading-tight"},[_vm._v(_vm._s(_vm.contractor.name))]),_c('div',{staticClass:"text-sm font-semibold flex mt-3"},[_c('icon',{staticClass:"mt-px mr-2 text-gray-dark flex-shrink-0",attrs:{"icon":_vm.mdiHomeOutline,"size":_vm.IconSize.SM}}),_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.contractor.address)+", "+_vm._s(_vm.contractor.postalCode)+" "+_vm._s(_vm.contractor.city)+" ")]),_c('p',{staticClass:"mt-3"},[_c('span',{staticClass:"info-line"},[_vm._v(" "+_vm._s(_vm.$t('contractor-details-view.nip'))+": ")]),_vm._v(" "+_vm._s(_vm.contractor.nip)+" ")]),_c('p',[_c('span',{staticClass:"info-line"},[_vm._v(" "+_vm._s(_vm.$t('contractor-details-view.regon'))+": ")]),_vm._v(" "+_vm._s(_vm.contractor.regon)+" ")]),_c('p',[_c('span',{staticClass:"info-line"},[_vm._v(" "+_vm._s(_vm.$t('contractor-details-view.krs'))+": ")]),_vm._v(" "+_vm._s(_vm.contractor.krs)+" ")])])],1),_c('div',{staticClass:"flex mt-8"},[_c('router-link',{attrs:{"to":{
                  name: 'EditContractor',
                  params: { contractorId: _vm.contractor.id },
                }}},[_c('icon',{staticClass:"mr-4 text-primary",attrs:{"icon":_vm.EditSvg,"disabled":_vm.deletionInProgress}})],1),_c('modal-wrapper',{staticClass:"cursor-pointer",attrs:{"content":_vm.deleteModalContent},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var runWithModal = ref.runWithModal;
return [_c('icon',{staticClass:"mr-4 text-primary",attrs:{"icon":_vm.DeleteSvg,"disabled":_vm.deletionInProgress},nativeOn:{"click":function($event){return runWithModal(_vm.onContractorDelete, _vm.deleteModalContent)}}})]}}],null,false,1345398670)})],1)])]),_c('div',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"flex items-center"},[_c('h2',{staticClass:"font-semibold text-lg"},[_vm._v(" "+_vm._s(_vm.$t('contractor-details-view.users'))+" ")]),_c('pagination-select',{attrs:{"query":_vm.routeQuery},on:{"select-limit":_vm.selectLimit}})],1),_c('div',{staticClass:"flex"},[_c('access-modal',{attrs:{"contractor-id":_vm.contractor.id}}),_c('btn',{staticClass:"min-w-32 md:min-w-48 ml-4",attrs:{"theme":_vm.BtnTheme.SECONDARY,"size":_vm.BtnSize.LG,"is-loading":_vm.deletionInProgress},on:{"click":function($event){return _vm.$router.push({ name: 'AddContractorUser' })}}},[_vm._v(" "+_vm._s(_vm.$t('contractor-details-view.add-user'))+" ")])],1)]),_c('tabs',{staticClass:"mt-4.5 md:mb-16"},[_c('tab',{attrs:{"name":_vm.$t('contractor-details-view.all'),"selected":""}},[_c('table-list',{staticClass:"md:pb-16 hover-click",attrs:{"content":"users","query":{ organizationId: _vm.$route.params.contractorId },"route-component":"ContractorDetails","loading":_vm.deletionInProgress,"cols":_vm.columns,"force-update":_vm.listUpdatesCounter,"ellipsis-only":"","mobile-cols":1,"actions":{
                edit: _vm.onUserEdit,
                delete: {
                  handler: _vm.onUserDelete,
                  modal: {
                    type: 'confirm',
                    title: _vm.$t('contractor-details-view.modal-title'),
                    description: _vm.$t(
                      'contractor-details-view.modal-description'
                    ),
                    btnText: _vm.$t('contractor-details-view.modal-btn'),
                  },
                },
              },"checkbox":"","selected-ids":_vm.selectedUsers},on:{"record-click":function($event){return _vm.$router.push({
                  name: 'ContractorUserDetails',
                  params: {
                    contractorId: _vm.$route.params.contractorId,
                    userId: $event,
                  },
                })},"select":function($event){_vm.selectedUsers = $event}}})],1)],1)],1)])]},proxy:true}:null,{key:"rightSide",fn:function(){return [_c('tabs',{staticClass:"p-8"},[_c('tab',{attrs:{"name":_vm.$t('contractor-details-view.documents'),"selected":""}},[(_vm.showAttachments('multimedia'))?_c('upload-list',{staticClass:"mt-6",attrs:{"type":"multimedia","list":_vm.attachments.multimedia,"read-only":""}}):_c('p',{staticClass:"file-list-placeholder"},[_vm._v(" "+_vm._s(_vm.$t('contractor-form.multimedia-placeholder'))+" ")]),(_vm.showAttachments('document'))?_c('upload-list',{staticClass:"mt-6",attrs:{"type":"document","list":_vm.attachments.document,"read-only":""}}):_c('p',{staticClass:"file-list-placeholder"},[_vm._v(" "+_vm._s(_vm.$t('contractor-form.documents-placeholder'))+" ")])],1),_c('tab',{attrs:{"name":((_vm.$t('contractor-details-view.structures')) + " / " + (_vm.$t(
          'contractor-details-view.places'
        )))}},[_c('structures-places-list',{attrs:{"read-only":""}})],1)],1)]},proxy:true}],null,true)},[_c('overlap-sidebar',{attrs:{"is-open":_vm.sidebarOpen},on:{"close":function($event){_vm.sidebarOpen = false}}},[_c('clients-sidebar',{staticClass:"mt-4"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }