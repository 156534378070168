<template>
  <div>
    <div v-for="brand in brands" :key="brand.id" class="brand-wrapper">
      <label class="brand-label">
        {{ $t('contractor-form.brand') }}:
        <span class="text-primary font-bold ml-1">{{ brand.name }}</span>
      </label>
      <ValidationProvider v-slot="{ errors }" rules="required">
        <list
          :value="structures[brand.id]"
          :label="$t('contractor-form.structures')"
          required
          :is-error="errors[0]"
          :remove-btns="!readOnly"
          :cols="structureFields"
          :placeholder="$t('contractor-form.no-structures')"
          @open-sidebar="$emit('open-sidebar', { brand, tab: 'structures' })"
          @remove="removeStructure({ brandId: brand.id, id: $event.id })"
        ></list>
      </ValidationProvider>
      <div v-if="!show">
        <div class="flex">
          <p class="mr-2">{{ $t('contractor-form.places') }}:</p>
          <fetch-data
            v-if="edit"
            v-slot="{ pagination }"
            url="places"
            :params="{
              contractorId: $route.params.contractorId,
              limit: 1,
              brandId: brand.id,
            }"
            class="flex"
          >
            <p class="font-semibold">
              {{
                pagination
                  ? getSelectedPlacesCount(pagination.total, brand.id)
                  : 0
              }}
            </p>
          </fetch-data>
          <p v-else class="font-semibold">
            {{ getSelectedPlacesCount(0, brand.id) }}
          </p>
        </div>

        <btn
          class="focus:outline-none w-50 secondary md mt-4"
          @click="$emit('open-sidebar', { brand, tab: 'places' })"
        >
          {{ $t('contractor-form.show-all') }}
        </btn>
      </div>
      <div v-if="show">
        <ValidationProvider v-slot="{ errors }" rules="required">
          <list
            :value="places[brand.id]"
            :label="$t('contractor-form.places')"
            required
            :custom-list="true"
            :is-error="errors[0]"
            :cols="getPlaceCols(brand)"
            :placeholder="$t('contractor-form.no-places')"
            @remove="removePlace({ brandId: brand.id, id: $event.id })"
            @open-sidebar="$emit('open-sidebar', { brand, tab: 'places' })"
          >
            <TableList
              v-if="brand"
              ref="placeTable"
              :cols="getPlaceCols(brand)"
              content="places"
              :query="{
                contractorId: $route.params.contractorId,
                brandId: brand.id,
              }"
              :remove-btns="!readOnly"
              hide-labels
              @remove="removePlace({ brandId: brand.id, id: $event.id })"
              @select="(ids, items) => $emit('select', ids, items)"
            ></TableList>
          </list>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { fontNormal, customClass } from '@/util/formatters';
import List from '@/components/FormElements/List';
import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import TableList from '@/components/Table/TableList';
import FetchData from '@/components/DataProvider/FetchData.vue';
import Btn from '@/components/Button/Button';
import { getPlaceRoute } from '@/util/routing';

export default {
  components: {
    TableList,
    List,
    ValidationProvider,
    FetchData,
    Btn,
  },
  props: {
    removeBtns: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      structureFields: {
        name: {
          formatter: (value) => customClass(value, 'font-semibold'),
        },
      },
    };
  },
  computed: {
    ...mapState('ui', {
      mobileView: (state) => state.mobileView.active,
    }),
    ...mapState('contractor', [
      'brands',
      'structures',
      'places',
      'placesToAdd',
      'placesToRemove',
    ]),
  },
  created() {
    extend('required', {
      ...required,
      message: this.$t('errors.error-empty'),
    });
  },
  beforeDestroy() {
    this.clearState();
  },
  methods: {
    ...mapActions('contractor', ['removeStructure', 'removePlace']),
    ...mapActions('structures', ['clearState']),
    getPlaceCols(brand) {
      return {
        number: {
          routerLink: {
            getRoute: (place) => getPlaceRoute(place, brand.id),
          },
          colClass: ['w-24'],
        },
        name: {
          formatter: (value) => customClass(value, 'font-semibold'),
        },
        address: {
          merge: {
            props: ['address', 'postalCode', 'city'],
            format: `$0, $1 $2`,
          },
          formatter: fontNormal,
        },
      };
    },
    getSelectedPlacesCount(total, brandId) {
      const addedCount = (this.placesToAdd[brandId] || []).length;
      const removedCount = (this.placesToRemove[brandId] || []).length;

      const baseCount = total - removedCount;

      return baseCount < 0 ? addedCount : baseCount + addedCount;
    },
  },
};
</script>

<style lang="scss" scoped>
.brand-wrapper {
  @apply border px-4 py-6 my-8 border-gray-light rounded relative;

  .brand-label {
    @apply absolute bg-gray-lighter px-2;
    top: -0.8rem;
    left: 0.5rem;
  }
}

.list-placeholder {
  @apply text-lg text-gray-dark py-2;

  button {
    @apply flex items-center justify-center w-full;
  }
}

.item {
  @apply mr-2 font-semibold;
  &:last-child {
    &:after {
      content: '...';
    }
  }
}
</style>
