import { NodeType, } from '@/views/Dashboard/Contractors/_components/AccessTree';
import { getBrandsList } from '@/api/requests/brand';
import { getStructuresList, } from '@/api/requests/structures';
import { getPlacesList } from '@/api/requests/places';
export const useAccessTreeContent = (contractorId) => {
    const getTreeNode = (node, nodeType) => {
        return { ...node, nodeType };
    };
    const getBrandNodes = async (clientId, page) => {
        const { results, pagination } = await getBrandsList({
            clientId,
            contractorIdAccess: contractorId,
            page,
        });
        return {
            children: results.map((brand) => getTreeNode(brand, NodeType.BRAND)),
            pagination,
        };
    };
    const getStructureNodes = async (query) => {
        const { results, pagination } = await getStructuresList({
            ...query,
            contractorIdAccess: contractorId,
        });
        return {
            children: results.map((structure) => getTreeNode(structure, NodeType.STRUCTURE)),
            pagination,
        };
    };
    const getPlaceNodes = async (structureId, page) => {
        const { results, pagination } = await getPlacesList({
            structureId,
            contractorIdAccess: contractorId,
            page,
        });
        return {
            children: results.map((place) => getTreeNode(place, NodeType.PLACE)),
            pagination,
        };
    };
    const getStructureChildren = (node, page) => {
        return node.hasChildren
            ? getStructureNodes({ parentId: node.id, page })
            : getPlaceNodes(node.id, page);
    };
    const loadChildren = async (node, page = 1) => {
        switch (node.nodeType) {
            case NodeType.CLIENT: {
                return getBrandNodes(node.id, page);
            }
            case NodeType.BRAND: {
                return getStructureNodes({ brandId: node.id, parentId: 'null', page });
            }
            case NodeType.STRUCTURE: {
                return getStructureChildren(node, page);
            }
        }
    };
    const loadMore = async (node, pagination) => {
        return loadChildren(node, pagination.value.currentPage + 1);
    };
    return {
        getTreeNode,
        loadChildren,
        loadMore,
    };
};
